<template>
  <downhill-times
    v-if="gameplayTableState.showTables"
    :row-data="gameplayTableState.tableData"
    :player-data="gameplayTableState.playerData"
    :show-left-table="true"
    :show-right-table="false"
  />
</template>

<script lang="ts">
import { DownhillTimes } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'DownhillTimesComponent',
  components: {
    DownhillTimes
  },
  computed: {
    ...mapGetters({
      gameplayTableState: 'GameplayTableState/getGameplayTableState'
    })
  }
})
</script>

<style lang="less" scoped>
</style>
