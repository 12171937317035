import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface PlayerState {
  speed: number,
  centrifugalForce: number,
  centripetalForce: number,
  slope: number,
  offsetFromIdeal: number
}

const initialState = () => ({
  speed: 1,
  centrifugalForce: 0,
  centripetalForce: 0,
  slope: 0,
  offsetFromIdeal: 0
})

const mainState: Module<PlayerState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getMainState: (moduleState: PlayerState) => moduleState
  },

  mutations: {
    RESET: (moduleState: PlayerState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: PlayerState, newState: PlayerState) => {

      if (newState.speed !== undefined) moduleState.speed = newState.speed
      if (newState.centrifugalForce !== undefined) {

        moduleState.centrifugalForce = newState.centrifugalForce

      }
      if (newState.centripetalForce !== undefined) {

        moduleState.centripetalForce = newState.centripetalForce

      }
      if (newState.slope !== undefined) moduleState.slope = newState.slope
      if (newState.offsetFromIdeal !== undefined) {

        moduleState.offsetFromIdeal = newState.offsetFromIdeal

      }

    }
  }
}

export default mainState
