import {
  THREE,
  AnimationsManager,
  game,
  CallbackAnimationTypes,
  gsap,
  playersManager,
  PlayerSex,
  cameraManager,
  fpsManager
} from '@powerplay/core-minigames'
import {
  gameConfig,
  animationsConfig
} from '../../config'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  ModelsNames,
  DisciplinePhases
} from '../../types'
import { PlayerInitializator } from './PlayerInitializator'
import { HillLinesCreator } from '../hill/HillLinesCreator'
import { playerMovementManager } from './PlayerMovementManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'

/**
 * Trieda pre hraca
 */
export class Player {

  /** 3D objekt lyziara - cela scena */
  public playerObject: THREE.Object3D = new THREE.Object3D()

  /** 3D objekt lyziara - cela scena */
  public playerObjectSecond: THREE.Object3D = new THREE.Object3D()

  /** Manager pre animacie */
  public animationsManager!: AnimationsManager

  /** Manager pre animacie pre druheho hraca */
  public animationsManagerSecond!: AnimationsManager

  /** 3D objekt, v ktorom sa nachadza kamera a ktoremu sa meni pozicia lerpom postupne */
  private goalObject: THREE.Object3D = new THREE.Object3D()

  /** dolezity manager na pohyb */
  public hillLinesManager!: HillLinesCreator

  /** hrac je sprinting */
  public isSprinting = false

  private playerInitializator: PlayerInitializator = new PlayerInitializator()

  /** Upravuje vysku hraca nad tratou */
  private readonly PLAYER_POSITION_Y_ADJUST = -1.2

  /** Ci su pauznute animacie */
  private animationsPaused = false

  /** Nazov body hraca */
  public readonly PLAYER_BODY_NAME = 'body'

  /**
   * Vytvorenie lyziara
   * @param position - Startovacia pozicia lyziara
   */
  public create(position = gameConfig.startPosition): void {

    console.log('vytvaram hraca...')

    this.playerObject = this.playerInitializator.createPlayerGameObject(
      ModelsNames.skier,
      position,
      this.PLAYER_POSITION_Y_ADJUST
    )

    // animacie
    this.animationsManager = new AnimationsManager(
      this.playerObject,
      animationsConfig,
      game.animations.get(ModelsNames.skier),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    this.goalObject.position.set(position.x, position.y, position.z + 2)

    // Hill Line pre pohyb
    this.hillLinesManager = new HillLinesCreator()
    this.hillLinesManager.createLines()
    console.log('hrac vytvoreny...')

    // musime nastavit zakladnu rychlost
    speedManager.setStartCoefsFromAttributes()

  }

  /**
   * naklonovanie hraca
   */
  public clonePlayer(): void {

    // this.playerObjectSecond = SkeletonUtils.clone(this.playerObject)
    this.playerObjectSecond = game.cloneSkeleton(this.playerObject)
    this.playerObjectSecond.name = 'Player_Second'
    game.scene.add(this.playerObjectSecond)
    const bobsledRig = this.playerObjectSecond.getObjectByName('bobsled_rig')
    if (bobsledRig) bobsledRig.visible = false

    // animacie
    this.animationsManagerSecond = new AnimationsManager(
      this.playerObjectSecond,
      animationsConfig,
      game.animations.get(ModelsNames.skier),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManagerSecond.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManagerSecond.resetSpeed()

  }

  /**
   * vytvorime tien osobitne aby sa nam neduplikoval
   */
  public createShadow(): void {

    this.playerInitializator.createShadow(this.playerObject)

  }

  /**
   * Aktualizovanie pozicie lyziara
   */
  private updatePlayerPosition(positionObj: THREE.Object3D): void {

    this.playerObject.position.copy(positionObj.position)

    this.playerObject.quaternion.slerp(positionObj.quaternion, 1)

    this.playerObjectSecond.position.copy(this.playerObject.position)
    this.playerObjectSecond.quaternion.copy(this.playerObject.quaternion)

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.playerObject.quaternion

  }

  /**
   * Vratenie pozicie lyziara
   * @returns Pozicia lyziara
   */
  public getPosition(): THREE.Vector3 {

    return this.playerObject.position

  }

  /**
   * Ukoncenie fazy finishu, ked skonci konecna animacia
   */
  private endFinishPhaseAfterEndAnimationDone = (animation: string): void => {

    this.animationsManager.removeAnimationCallback(
      animation,
      CallbackAnimationTypes.loop
    )

    gsap.to({}, {
      duration: 1,
      onComplete: () => {

        disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.finish).finishPhase()

      },
      callbackScope: this
    })

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   */
  public updateBeforePhysics(): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return
    playerMovementManager.update()
    const point = this.hillLinesManager.update()
    this.updatePlayerPosition(point)

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   * @param hillMesh - Mesh kopca
   */
  public updateAfterPhysics(/* hillMesh: THREE.Mesh */): void {

    // nic

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    if (this.animationsPaused) return

    this.animationsManager.update(delta)
    this.animationsManagerSecond.update(delta)

  }

  /**
   * Konecna akcia pre hraca
   * @param emotion - Typ emocie
   */
  public finishAction(): void {

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number,
    isStaticMovement?: boolean
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp,
      isStaticMovement
    )

  }

  /**
   * Zapnutie/vypnutie optimalizacii pre jazdu
   * @param enabled - True, ak maju byt zapnute
   */
  public setEnabledOptimalizationForDrive(enabled: boolean): void {

    this.animationsPaused = enabled

    // deaktivujeme tiene
    game.shadowsManager.setActive(!enabled)
    // game.shadowsManager.setVisibility(false)

    this.setVisibleBodies(!enabled)

  }

  /**
   * Nastavenie viditelnosti bodycok hracov
   * @param visible - True, ak maju byt viditelne
   */
  private setVisibleBodies(visible: boolean): void {

    const playerSexPostfix = playersManager.getPlayer().sex === PlayerSex.male ? '' : '_w'
    const playerBodyName = this.PLAYER_BODY_NAME + playerSexPostfix
    const playerBody = this.playerObject.getObjectByName(playerBodyName)
    const playerSecondBody = this.playerObjectSecond.getObjectByName(playerBodyName)

    // ak to neexistuje, tak nevadi, nemusime davat error
    if (!playerBody || !playerSecondBody) return

    playerBody.visible = visible
    playerSecondBody.visible = visible

  }

  /**
   * reset hraca
   */
  public reset(): void {

    this.animationsManager.resetAll()
    this.animationsManagerSecond.resetAll()
    this.hillLinesManager.reset()
    speedManager.reset()
    playerMovementManager.reset()

  }

}

export const player = new Player()
