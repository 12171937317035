import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface SpeedmeterState {
  visible: boolean,
  speed: number,
  curveEfficiency: number,
  actualPosition: number,
  showBottomText: boolean
}

const initialState = () => ({
  visible: false,
  speed: 0,
  curveEfficiency: 0,
  actualPosition: 0,
  showBottomText: false
})

const speedmeterState: Module<SpeedmeterState, RootState> = {
  state: initialState(),
  getters: {
    getSpeedmeterState: (moduleState: SpeedmeterState) => moduleState,
    getIsVisible: (moduleState: SpeedmeterState) => moduleState.visible,
    getActualPosition: (moduleState: SpeedmeterState) => moduleState.actualPosition,
    getShowBottomText: (moduleState: SpeedmeterState) => moduleState.showBottomText
  },
  mutations: {
    RESET: (moduleState: SpeedmeterState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: SpeedmeterState, newState: SpeedmeterState) => {

      if (newState.visible !== undefined) moduleState.visible = newState.visible
      if (newState.speed !== undefined) moduleState.speed = newState.speed
      if (newState.curveEfficiency !== undefined) {

        moduleState.curveEfficiency = newState.curveEfficiency

      }

    },
    SET_VISIBILITY: (moduleState: SpeedmeterState, visible: boolean) => {

      moduleState.visible = visible

    },
    SET_CURVE_EFFICIENCY: (moduleState: SpeedmeterState, val: number) => {

      moduleState.curveEfficiency = val

    },
    SET_ACTUAL_POSITION: (moduleState: SpeedmeterState, val: number) => {

      moduleState.actualPosition = val

    },
    SET_SHOW_BOTTOM_TEXT: (moduleState: SpeedmeterState, val: boolean) => {

      moduleState.showBottomText = val

    }

  },
  namespaced: true
}

export default speedmeterState
