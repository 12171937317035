import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface MovementState {
  positionX: number,
  positionY: number,
  onlyHorizontal: boolean
}

const initialState = () => ({
  positionX: 0,
  positionY: 0,
  onlyHorizontal: false
})

const movementState: Module<MovementState, RootState> = {
  state: initialState(),
  getters: {
    getDirectionsState: (moduleState: MovementState) => moduleState,
    getPositionX: (moduleState: MovementState) => moduleState.positionX,
    getPositionY: (moduleState: MovementState) => moduleState.positionY,
    getIsHorizontal: (moduleState: MovementState) => moduleState.onlyHorizontal
  },
  mutations: {
    RESET: (moduleState: MovementState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: MovementState, newState: MovementState) => {

      moduleState.positionX = newState.positionX

    },
    SET_POSITION_X: (moduleState: MovementState, positionX: number) => {

      moduleState.positionX = positionX

    },
    SET_POSITION_Y: (moduleState: MovementState, positionY: number) => {

      moduleState.positionY = positionY

    },
    SET_HORIZONAL: (moduleState: MovementState, isHorizontal: boolean) => {

      moduleState.onlyHorizontal = isHorizontal

    }
  },
  namespaced: true
}

export default movementState
