import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { JumpInPhaseManager } from '@/app/phases/JumpInPhase/JumpInPhase'
import type { DrivePhaseManager } from '@/app/phases/DrivePhase/DrivePhase'
import type { StartUpPhaseManager } from '@/app/phases/StartUpPhase/StartUpPhase'
import {
  AudioGroups,
  AudioNames,
  CurveTypes,
  DisciplinePhases,
  SectorFullTurnTypes,
  type TriggerData,
  TriggersTypes,
  TutorialEventType
} from '@/app/types'
import {
  audioManager,
  modes,
  timeManager,
  gsap,
  TimesTypes
} from '@powerplay/core-minigames'
import {
  gameConfig,
  jumpInPhaseConfig,
  triggersConfig
} from '@/app/config'
import { SplitTimeManager } from '../player/SplitTimeManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { player } from '../player'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import store from '@/store'
import { playerMovementManager } from '../player/PlayerMovementManager'
import { trainingTasks } from '@/app/modes/training/TrainingTasks'
import { audioHelper } from '@/app/audio/AudioHelper'
import { endManager } from '@/app/EndManager'
import { hill } from '../hill/Hill'

/**
 * Klassa na triggery pre prechod medzi modmi hry
 */
export class TriggersManager {

  /** Index aktualneho triggera */
  private actualIndex = 0

  /** split time manager */
  public splitTimeManager = new SplitTimeManager()

  /** tween na zobrazenie rychlosti */
  private speedmeterTween!: gsap.core.Tween

  /** Ci bol uz prejdeny idealny jump in pri automove */
  private autoMoveIdealJumpInDone = false

  /** pocet prejdenych zakrut */
  public passedCurvesCounter = 0

  /** sortovane trigre, aby neboli problemy s poradim */
  public sortedTriggers = triggersConfig.triggers

  public constructor() {

    this.sortedTriggers.sort((a, b) => {

      if (a.points[0] > b.points[0]) return 1
      return -1

    })

  }

  /**
   * Skontrolovanie prejdenia aktualneho triggera
   * @param actualPercent - Aktualna % hodnota prejdenia trate
   * @param actualCurveType - Aktualny typ krivky (left|right)
   */
  public checkActualTrigger(actualPercent: number, actualCurveType: CurveTypes): void {

    const actualTriggerData = triggersConfig.triggers[this.actualIndex]

    if (actualTriggerData && actualPercent >= actualTriggerData.points[actualCurveType]) {

      // spravime co treba
      this.onPassedTrigger(actualTriggerData)

      // dame dalsi trigger v poradi
      this.actualIndex++

    }

    // ked mame automove, tak pozerame aj na trigger pre dokonaly jump in
    if (
      gameConfig.idealAutoMove.start &&
            actualPercent >= jumpInPhaseConfig.idealPercent &&
            !this.autoMoveIdealJumpInDone
    ) {

      const jumpinPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.jumpIn) as JumpInPhaseManager
      jumpinPhase.setJumpInPoint()
      this.autoMoveIdealJumpInDone = true

    }

  }

  /**
   * Poriesenie veci pri prejdeni triggerom
   * @param data - Data o triggeri
   */
  private onPassedTrigger(data: TriggerData): void {

    switch (data.type) {

      case TriggersTypes.timer:
        // Trigger - zaciatok pocitania casu
        this.onTriggerTimer()
        break

      case TriggersTypes.jumpInPhase:
        // Trigger - naskocenie
        this.onTriggerJumpInPhase()
        break

      case TriggersTypes.drivePhase:
        // Trigger - Zaciatok ovladania
        this.onTriggerDrivePhase()
        break

      case TriggersTypes.beforeFinish:
        // Trigger - pred koncom
        this.onTriggerBeforeFinish()
        break

      case TriggersTypes.finishPhase:
        // Trigger - koniec
        this.onTriggerFinishPhase()
        break

      case TriggersTypes.splitTime:
        this.onTriggerSplitTime()
        break

      case TriggersTypes.beforeSplitTime:
        this.onTriggerPreSplitTime()
        break

      case TriggersTypes.sectorLeftToRightStart:
      case TriggersTypes.sectorRightToLeftStart:
        this.onTriggerSectorStart()
        break

      case TriggersTypes.sectorRightToLeftStartFullTurn:
      case TriggersTypes.sectorLeftToRightStartFullTurn:
        this.onTriggerSectorStartFullTurn()
        break

      case TriggersTypes.sectorRightToLeftEndFullTurn:
      case TriggersTypes.sectorLeftToRightEndFullTurn:
        this.onTriggerSectorEndFullTurn()
        break

      case TriggersTypes.afterLastTurn:
        this.displayActualSpeed()
        break

      case TriggersTypes.bellsStart:
        this.onTriggerBellsStart()
        break

      case TriggersTypes.bellsEnd:
        this.onTriggerBellsStop()
        break

      case TriggersTypes.nextTrackPart:
        this.onTriggerNextTrackPart()
        break

    }

  }

  /**
   * Prejdenie triggera - spustenie casu
   */
  private onTriggerTimer(): void {

    timeManager.setActive(TimesTypes.game, true)

  }

  /**
   * Prejdenie triggera - naskocenie
   */
  private onTriggerJumpInPhase(): void {

    const startUpPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.startUp) as StartUpPhaseManager

    startUpPhase.finishPhase()

  }

  /**
   * Prejdenie triggera - faza jazdy
   */
  private onTriggerDrivePhase(): void {

    if (disciplinePhasesManager.getActualPhase() >= DisciplinePhases.drive) return

    const JumpInPhase = disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.jumpIn) as JumpInPhaseManager

    JumpInPhase.finishPhase()

    // skipnutie az na koniec kvoli debugu
    if (gameConfig.skipToFinish.active) {

      player.hillLinesManager.setActualPercent(gameConfig.skipToFinish.percent)

    }

  }

  /**
   * Prejdenie triggera - pred cielom
   */
  private onTriggerBeforeFinish(): void {

    this.splitTimeManager.manageStateBeforeFinish()

    if (audioManager.isAudioGroupPlaying(AudioGroups.commentators)) return
    audioManager.play(AudioNames.commentBeforeFinish)

  }

  /**
   * Prejdenie triggera - ciel
   */
  private onTriggerFinishPhase(): void {

    if (disciplinePhasesManager.getActualPhase() >= DisciplinePhases.finish) return

    const drivePhase = disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.drive) as DrivePhaseManager

    drivePhase.finishPhase()
    tutorialFlow.eventActionTrigger(TutorialEventType.finish)

    this.onTriggerBellsStop()

  }

  /**
   * Prejdenie triggera - medzicas
   */
  private onTriggerSplitTime(): void {

    if (modes.isTutorial()) return

    // cekneme split time veci
    this.splitTimeManager.checkActualSplit(timeManager.getGameTimeWithPenaltyInSeconds(true, 3))

    // zistime skutocnu poziciu
    const position = this.splitTimeManager.getPlayerPosition(timeManager.getGameTimeWithPenaltyInSeconds(true, 3))

    store.commit('SpeedmeterState/SET_ACTUAL_POSITION', position)
    store.commit('SpeedmeterState/SET_SHOW_BOTTOM_TEXT', true)
    this.displayActualSpeed()

  }

  /**
   * Prejdenie triggera - pred medzicasom
   */
  private onTriggerPreSplitTime(): void {

    if (modes.isTutorial()) return

    this.splitTimeManager.manageStateBeforeSplitTime()

  }

  /**
   * Prejdenie triggera - zaciatok sektora zakruty
   */
  private onTriggerSectorStart(): void {

    player.hillLinesManager.getHillLineNormalizer().fullTurnType =
            SectorFullTurnTypes.minimumOnStart

  }

  /**
   * Prejdenie triggera - zaciatok sektora zakruty, ked uz je najsirsia
   */
  private onTriggerSectorStartFullTurn(): void {

    player.hillLinesManager.getHillLineNormalizer().fullTurnType =
            SectorFullTurnTypes.fullOnMiddle

  }

  /**
   * Prejdenie triggera - koniec sektora zakruty, ked uz je najsirsia
   */
  private onTriggerSectorEndFullTurn(): void {

    this.passedCurvesCounter += 1

    player.hillLinesManager.getHillLineNormalizer().fullTurnType =
            SectorFullTurnTypes.minimumOnEnd

    const offsetFromIdeal = player.hillLinesManager.lastOffsetFromIdealInTurn
    console.log('vyjazd zo zakruty a ideal je aky?', offsetFromIdeal)

    playerMovementManager.setExitTurnPenalty(offsetFromIdeal)

    trainingTasks.onCurvePassed()

  }

  /**
   * Prejdenie triggera - zaciatok zvuku zvonov
   */
  private onTriggerBellsStart(): void {

    audioHelper.playAudioBells()

  }

  /**
   * Prejdenie triggera - koniec zvuku zvonov
   */
  private onTriggerBellsStop(): void {

    audioHelper.stopAudioBells()

  }

  /**
   * Prejdenie triggera - zobrazenie dalsej casti trate
   */
  private onTriggerNextTrackPart(): void {

    hill.nextTrackPart()

  }

  /**
   * Zobrazenie aktualnej rychlosti
   */
  private displayActualSpeed(): void {

    if (modes.isTrainingMode()) return

    const actualSpeed = speedManager.getActualSpeed()

    if (actualSpeed > endManager.maxSpeedLog) endManager.maxSpeedLog = actualSpeed

    if (this.speedmeterTween) this.speedmeterTween.progress(1)

    store.commit('SpeedmeterState/SET_STATE', {
      visible: true,
      speed: actualSpeed.toFixed(2)
    })

    this.speedmeterTween = gsap.to({}, {
      duration: 2,
      onComplete: () => {

        store.commit('SpeedmeterState/SET_VISIBILITY', false)

      }
    })

  }

  /**
   * Vratenie typu plnosti zakruty podla percent trate
   * @param percent - % trate
   * @param sectorIndex - Index sektora
   * @returns Typ plnosti zakruty
   */
  public getFullTurnTypeByPercent(percent: number, sectorIndex: number): SectorFullTurnTypes {

    const hillNormalizer = player.hillLinesManager.getHillLineNormalizer()
    const config = hillNormalizer.getSectorFullTurnConfig()

    if (percent < config[sectorIndex - 1].points[CurveTypes.left]) {

      return SectorFullTurnTypes.minimumOnStart

    }

    if (percent >= config[sectorIndex].points[CurveTypes.left]) {

      return SectorFullTurnTypes.minimumOnEnd

    }

    return SectorFullTurnTypes.fullOnMiddle

  }

  public reset(): void {

    this.actualIndex = 0
    this.splitTimeManager.reset()
    this.autoMoveIdealJumpInDone = false
    this.passedCurvesCounter = 0

  }

}

export const triggersManager = new TriggersManager()
