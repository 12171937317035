import { Sides } from '@/app/types'
import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface ImpactState {
  side: Sides,
  isVisible: boolean
}

const initialState = () => ({
  side: Sides.LEFT,
  isVisible: false
})

const impactState: Module<ImpactState, RootState> = {
  state: initialState(),
  getters: {
    getImpactState: (moduleState: ImpactState) => moduleState
  },
  mutations: {
    RESET: (moduleState: ImpactState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: ImpactState, newState: ImpactState) => {

      if (newState.side) {

        moduleState.side = newState.side

      }
      moduleState.isVisible = newState.isVisible

    }
  },
  namespaced: true
}

export default impactState
