<template>
  <traffic-light-box
    v-if="jumpInMessageState.showMessage"
    class="traffic-light-box"
    :color="jumpInMessageState.messageColor"
    :text="$t(jumpInMessageState.messageText)"
  />
</template>

<script lang="ts">
/* eslint-disable max-len */
import { mapGetters } from 'vuex'
import { TrafficLightBox } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TrafficComponent',
  components: {
    TrafficLightBox
  },
  computed: {
    ...mapGetters({
      jumpInMessageState: 'JumpInMessageState/getJumpInMessageState'
    })
  }
})
</script>

<style lang="less" scoped>
.traffic-light-box {
    position: absolute;
    left: 50%;
    top: 5%;
}
</style>
