<template>
  <div
    class="training-layout"
  >
    <training-left-top-box
      :text="$t('highScore')"
      :points="String(highScore)"
      :tasks="trainingState.tasks"
      :max-t-p-length="maxTPLength"
    />
    <training-blue-box
      v-if="trainingState.showNewHighScore"
      :points="String(trainingState.newHighScore)"
      :text="$t('newHighScore')"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import {
  WindowAspect,
  TrainingBlueBox,
  TrainingLeftTopBox
} from '@powerplay/core-minigames-ui'
import { trainingManager } from '@powerplay/core-minigames'

export default defineComponent({
  name: 'TrainingLayout',
  components: {
    TrainingLeftTopBox,
    TrainingBlueBox
  },
  mixins: [WindowAspect],
  props: {
  },
  computed: {
    ...mapGetters({
      trainingState: 'TrainingState/getTrainingState'
    }),
    highScore(): string {

      return String(trainingManager.bestScore)

    },
    maxTPLength() {

      return trainingManager.getTpPerTask().toString().length

    }
  }
})
</script>

<style lang="less" scoped>
.training-layout {
    position: absolute;
    left: 1%;
    top: 30px;
    width: 100%;
    height: 100%;

    .training-blue-box {
        position: fixed;
        top: calc(50% - 30px);
        left: 50%;
    }
}
</style>
