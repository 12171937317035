import { minigameConfig } from '@powerplay/core-minigames'
import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface TimerState {
  penaltySeconds: number,
  timeWithPenalty: string,
  time: string,
  v2Expanded: boolean,
  showDiff: boolean,
  diffIndex: number,
  isV1: boolean,
  doTimeUpdate: boolean,
  bestTime: number,
  isFinish: boolean
}

const initialState = () => ({
  penaltySeconds: 0,
  timeWithPenalty: '0.0',
  time: '0.0',
  v2Expanded: false,
  showDiff: false,
  diffIndex: 0,
  isV1: true,
  doTimeUpdate: true,
  bestTime: minigameConfig.dnfValue,
  isFinish: false
})

const timeState: Module<TimerState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTimeState: (moduleState: TimerState) => moduleState,
    getPenaltySeconds: (moduleState: TimerState) => moduleState.penaltySeconds,
    getTimeWithPenalty: (moduleState: TimerState) => moduleState.timeWithPenalty,
    getTime: (moduleState: TimerState) => moduleState.time
  },

  mutations: {
    RESET: (moduleState: TimerState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TimerState, newState: TimerState) => {

      moduleState.penaltySeconds = newState.penaltySeconds
      moduleState.timeWithPenalty = newState.timeWithPenalty
      moduleState.time = newState.time

    },
    SET_SHOW_BOOLEANS: (
      moduleState: TimerState,
      newState: {
        v2Expanded: boolean,
        showDiff: boolean,
        diffIndex: number,
        isV1: boolean,
        doTimeUpdate: boolean,
        bestTime: number,
        isFinish: boolean
      }
    ) => {

      moduleState.v2Expanded = newState.v2Expanded
      moduleState.showDiff = newState.showDiff
      moduleState.diffIndex = newState.diffIndex
      moduleState.isV1 = newState.isV1
      moduleState.doTimeUpdate = newState.doTimeUpdate
      moduleState.bestTime = newState.bestTime
      moduleState.isFinish = newState.isFinish

    }
  }
}

export default timeState
