<template>
  <!-- v-if="showComponent" -->
  <div
    v-if="showComponent"
    class="split-position-text-container"
    :style="[style, { transform: transformCoef }]"
  >
    <div :style="getScaleAndOpacity">
      <img :src="getImgUrl">
    </div>
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { pathAssets } from '@/globals/globalvariables'
import {
  gsap,
  modes
} from '@powerplay/core-minigames'
import { WindowAspect } from '@powerplay/core-minigames-ui'
import { speedMeterConfig } from '@/app/config'

export default defineComponent({
  name: 'SplitPositionText',
  mixins: [WindowAspect],
  props: {
    /**
     * Sets the styles. To make the native style binding work.
     */
    style: {
      type: Object,
      default: () => ({})
    },
  },
  data: function() {

    return {
      showComponent: false,
      actualVals: { scale: 0,
        opacity: 0 },
      tweenScale: undefined,
      tweenOpacity: undefined,
      tweenHideComponent: undefined

    }

  },
  computed: {
    ...mapGetters({
      showBottomText: 'SpeedmeterState/getShowBottomText',
      position: 'SpeedmeterState/getActualPosition'
    }),
    getImgUrl() {

      return `${pathAssets}/ui/splittimes/split-time-${this.position}.png`

    },
    getScaleAndOpacity() {

      return `opacity: ${this.actualVals.opacity};transform: scale(${this.actualVals.scale});`

    },
    transformCoef() {

      return `scale(${ this.scaleCoef })`

    }
  },
  watch: {
    showBottomText(newVal) {

      if (newVal) {

        if (this.position < 1 || this.position > 3 || modes.isTrainingMode()) {

          this.$store.commit('SpeedmeterState/SET_SHOW_BOTTOM_TEXT', false)
          return

        }

        this.actualVals.scale = speedMeterConfig.bottomText.initValues.scale
        this.actualVals.opacity = speedMeterConfig.bottomText.initValues.opacity
        this.startAnimation()

      }

    }
  },
  methods: {
    startAnimation(): void {

      if (this.tweenScale) this.tweenScale.kill()
      if (this.tweenOpacity) this.tweenOpacity.kill()
      if (this.tweenHideComponent) this.tweenHideComponent.kill()

      this.showComponent = true
      const config = speedMeterConfig.bottomText

      this.tweenScale = gsap.timeline().to(this.actualVals, { // zvacsime
        duration: config.scaleFirst.seconds,
        scale: config.scaleFirst.value
      }).to({}, { // cakame
        duration: config.pauseSeconds.scale
      }).to(this.actualVals, { // zmensujeme
        scale: config.scaleFinal.value,
        duration: config.scaleFinal.seconds
      })

      this.tweenOpacity = gsap.timeline().to({}, { // cakame na zaciatku
        duration: config.opacityStartSeconds
      }).to(this.actualVals, { // zvacsime
        duration: config.opacityFirst.seconds,
        opacity: config.opacityFirst.value
      }).to({}, { // cakame
        duration: config.pauseSeconds.opacity
      }).to(this.actualVals, { // zmensujeme
        opacity: config.opacityFinal.value,
        duration: config.opacityFinal.seconds
      })

      // na ked sa dokonci dlhsi tween tak skryjeme komponent
      const totalTime = Math.max(
        config.scaleFirst.seconds + config.pauseSeconds.scale + config.scaleFinal.seconds,
        config.opacityStartSeconds + config.opacityFirst.seconds +
                config.pauseSeconds.opacity + config.opacityFinal.seconds
      )

      this.tweenHideComponent = gsap.to({}, {
        duration: totalTime,
        onComplete: () => {

          this.showComponent = false
          this.$store.commit('SpeedmeterState/SET_SHOW_BOTTOM_TEXT', false)

        }
      })

    }
  }
})
</script>

<style lang="less" scoped>
.split-position-text-container{
    position: absolute;
    bottom: 0%;
    left: 0;
    right: 0;
    transform-origin: bottom center;

    img {
        transform: scale(0.75)
    }
}
</style>
