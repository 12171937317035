import {
  gsap,
  cameraManager,
  CameraStates,
  MobileDetector,
  corePhasesManager,
  audioManager,
  modes
} from '@powerplay/core-minigames'
import {
  AudioNames,
  type DisciplinePhaseManager
} from '../../types'
import store from '@/store'
import { player } from '../../entities/player'
import { inputsManager } from '../../InputsManager'
import {
  gameConfig,
  startUpPhaseConfig
} from '../../config'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { playerAnimationManager } from '@/app/entities/player/PlayerAnimationManager'
import { pushbarManager } from '@/app/PushbarManager'
import { playerMovementManager } from '@/app/entities/player/PlayerMovementManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import { hill } from '@/app/entities/hill/Hill'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci uz je mozne odstartovat */
  private startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci bolo skipnute */
  private skipped = false

  /** ci faza skoncila */
  private ended = false

  /** ci je to uz resestnute */
  public isResetAttempt = false

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween !: gsap.core.Tween

  /** tween na zacatie disciplinoveho intra */
  private launchSystemTween!: gsap.core.Tween

  /** tween na spustenie pipania */
  private startRunSoundTween!: gsap.core.Tween

  /** timeline na spustenie pipania */
  private startRunSoundTimeline!: gsap.core.Timeline

  /** tween pipania */
  private soundCounterTween!: gsap.core.Timeline

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** Ci je aktivne spomalenie v tutoriali */
  private slowDownInTutorialEnabled = false

  /** Konstruktor */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Vratenie, ci je aktivne spomalenie v tutoriali
   * @returns True, ak je aktivne spomalenie
   */
  public isSlowDownInTutorialEnabled(): boolean {

    return !this.ended && this.slowDownInTutorialEnabled

  }

  /**
   * Aktivovanie spomalenia v tutoriali
   */
  public enableSlowDownInTutorial(coef: number): void {

    if (!modes.isTutorial()) return

    this.slowDownInTutorialEnabled = true
    player.animationsManager.setSpeed(coef)
    player.animationsManagerSecond.setSpeed(coef)
    speedManager.setSlowDownCoef(coef)
    pushbarManager.setSlowDownCoef(coef)

  }

  /**
   * Deaktivovanie spomalenia v tutoriali
   */
  public disableSlowDownInTutorial(): void {

    if (!modes.isTutorial()) return

    this.slowDownInTutorialEnabled = false
    player.animationsManager.resetSpeed()
    player.animationsManagerSecond.resetSpeed()
    speedManager.setSlowDownCoef(1)
    pushbarManager.setSlowDownCoef(1)

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    startPhaseStateManager.disableInputs()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    if (this.ended) this.reset()

    playerMovementManager.changeMoveLeftRightActive(false)
    console.warn('starting start phase')
    // Zobrazit meno hraca v UI
    this.showName = !modes.isTutorial()
    this.setCameraForDisciplineIntro()

    playerAnimationManager.preStartAnimation()
    playerAnimationManager.startBobStickAnim()

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    if (gameConfig.cameraConfig.enabled) {

      player.changeCameraSettings(
        gameConfig.cameraConfig.idealOffset,
        undefined,
        gameConfig.cameraConfig.coefSize,
        gameConfig.cameraConfig.changeLerp,
        gameConfig.cameraConfig.isStaticMovement
      )

    }
    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntro()
      if (this.isResetAttempt) return
      tutorialFlow.init()
      tutorialUIChange.init()
      return

    }

    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.afterCameraDisciplineIntro)

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  private afterCameraDisciplineIntro = (): void => {

    cameraManager.setState(CameraStates.discipline)
    this.launchSystemTween = gsap.to({}, {
      onComplete: this.launchSystem,
      callbackScope: this,
      duration: 0.5
    })

  }

  /**
   * Spustenie pipania
   */
  private launchSystem = (): void => {

    this.skippable = false
    startPhaseStateManager.enableInputs()

    cameraManager.setState(CameraStates.discipline)

    const duration = this.skipped ? 1 : 0

    // Zrusit zobrazenie mena hraca v UI
    this.showName = false

    startPhaseStateManager.postIntroUiState()

    player.hillLinesManager.createDebugPoints()
    player.hillLinesManager.showDebugLinesTriggers()
    player.hillLinesManager.setIdealJumpInMeshPosition()

    hill.setVisibilityOfAllTrackParts(false)
    hill.showFirstTrackParts()

    // davam maly delay kvoli tomu, ze predtym mohol byt skip
    this.startRunSoundTween = gsap.to({}, {
      onComplete: () => {

        const coef = startUpPhaseConfig.tutorialSlowDownCoef
        this.enableSlowDownInTutorial(coef)
        this.startable = true
        startPhaseStateManager.enableStartInputs()
        startPhaseStateManager.enableInputs()
        pushbarManager.prepareBar()

      },
      duration
    })

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    this.framesInPhase++

    if (MobileDetector.isMobile()) {

      if (store.getters['ActionButtonState/getStart'] && this.startable) {

        console.log('bol stlaceny button start')

        this.storeState()
        this.finishPhase()
        this.ended = true

      }

    } else {

      if (inputsManager.actionPressed && this.startable) {

        console.log('action pressed')

        this.storeState()
        this.finishPhase()
        this.ended = true

      }

    }

    if (inputsManager.actionPressed && this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      this.skipped = true
      cameraManager.skipTween()

    }

    this.storeState()

  }

  /**
   * kill all tweens
   */
  private killAllTweens(): void {

    if (this.launchSystemTween) this.launchSystemTween.kill()
    if (this.startRunSoundTween) this.startRunSoundTween.kill()
    if (this.soundCounterTween) this.soundCounterTween.kill()
    if (this.startRunSoundTimeline) this.startRunSoundTimeline.kill()

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    this.startable = false

    if (!this.ended) {

      audioManager.stopAudioByName(AudioNames.audienceNoise)
      audioManager.play(AudioNames.audienceHype)
      audioManager.play(AudioNames.start)

      console.warn('start phase ended')

      this.ended = true
      this.killAllTweens()
      this.callbackEnd()

      this.storeState()

      startPhaseStateManager.finishPhaseUiState()

    }

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    store.commit('StartPhaseState/SET_STATE', {
      clickedPower: 0,
      showName: this.showName,
      showPhase: !this.ended,
      showBar: this.startable && !this.ended,
      attempt: corePhasesManager.disciplineActualAttempt
    })

  }

  /**
   * reset fazy
   */
  public reset(): void {

    store.commit('InputsState/SET_DISABLED', true)
    this.skippable = true
    this.startable = false
    this.showName = false
    this.skipped = false
    this.ended = false
    this.framesInPhase = 0
    this.storeState()
    tutorialFlow.reset()

  }

}
