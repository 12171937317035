<template>
  <power-stick
    :only-horizontal="isHorizontal"
    @position-x="changeMovementX"
    @position-y="changeMovementY"
    @end-contact="endContact"
  />
</template>

<script lang="ts">
import { PowerStick } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { inputsManager } from '@powerplay/core-minigames'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'JoyStick',
  components: {
    PowerStick
  },
  computed: {
    ...mapGetters({
      isHorizontal: 'MovementState/getIsHorizontal'
    })
  },
  methods: {
    endContact() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()
      this.$store.commit('MovementState/SET_POSITION_X', 0)
      this.$store.commit('MovementState/SET_POSITION_Y', 0)

    },
    changeMovementX(positionX: number) {

      this.$store.commit('MovementState/SET_POSITION_X', positionX)

    },
    changeMovementY(positionY: number) {

      this.$store.commit('MovementState/SET_POSITION_Y', positionY)

    }
  }
})
</script>
