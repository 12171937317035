import {
  gameConfig,
  movementConfig
} from '@/app/config'
import { inputsManager } from '@/app/InputsManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import {
  CurveTypes,
  SectorFullTurnTypes,
  Sides,
  TriggersTypes
} from '@/app/types'
import store from '@/store'
import { player } from '.'
import { triggersManager } from '../trigger/TriggersManager'
import { gsap } from '@powerplay/core-minigames'

/**
 * Trieda pre manazer pohybu do stran
 */
export class PlayerMovementManager {

  /** Aktualne percento v priereze krivky pohybu */
  private actualPercent = movementConfig.center

  /** Aktualne virtualne percento v prirereze krivky pohybu, kvoli zakrutam */
  private virtualPercent = movementConfig.center

  /** Ovladanie fyziky on/off */
  private moveLeftRight = false

  /** Ci sme aktualne v zakrute alebo nie */
  private inTurn = false

  /** Ci sme aktualne v strede zakruty, kde je najsirsia */
  private inFullTurn = false

  /** Ci sme aktualne v pravotocivej zakrute */
  private inTurnLeftToRight = false

  /** Ci sme aktualne v lavotocivej zakrute */
  private inTurnRightToLeft = false

  /** Aktualny limit vlavo */
  private limitLeft = 0

  /** Aktualny limit vpravo */
  private limitRight = 0

  /** Penalizacia za vyjazd zo zakruty */
  private exitTurnPenalty = 0

  /** Pocet frameov, ktore este ostavaju na penalizaciu za vyjazd zo zakruty */
  private exitTurnPenaltyFrame = 0

  /** Sila pre odraz od mantinela */
  private reboundForce = 0

  /** Pocet frameov, ktore este ostavaju na odraz od mantinela */
  private reboundForceFrame = 0

  /** pocet framov stlaceny input */
  private inputFrame = 0

  /** tlacidlo stlacene predchadzajuci frame */
  private previousPressed: Sides | undefined

  private impactEffectTween: gsap.core.Tween | undefined

  /**
   * Vratenie aktualnych virtualnych percent pohybu do stran
   * @returns % pohybu do stran
   */
  public getVirtualPercent(): number {

    return this.virtualPercent

  }

  /**
   * Vratenie aktualnych percent pohybu do stran
   * @returns % pohybu do stran
   */
  public getActualPercent(): number {

    return this.actualPercent

  }

  /**
   * Nastavenie aktualnych percent napevno (napr kvoli automove)
   * @param percent - Hodnota percent
   */
  public setActualPercent(percent: number): void {

    this.actualPercent = percent

  }

  /**
   * Metoda na moznost blokovania otacania
   * @param state - boolean stavu moveLeftRight
   */
  public changeMoveLeftRightActive(state: boolean): void {

    this.moveLeftRight = state

  }

  /**
   * Pohyb na lavu stranu
   * @param inTurn - True, ak sme v zakrute
   */
  private toLeftSide(inTurn: boolean): void {

    this.resolveInputFrame(Sides.LEFT)

    this.actualPercent -= this.getStep(inTurn)

  }

  /**
   * Pohyb na pravu stranu
   * @param inTurn - True, ak sme v zakrute
   */
  private toRightSide(inTurn: boolean): void {

    this.resolveInputFrame(Sides.RIGHT)

    this.actualPercent += this.getStep(inTurn)

  }

  /**
   * Vyhodnotenie input frame-u
   * @param pressedSide - Stlacena strana inputu
   */
  private resolveInputFrame(pressedSide: Sides): void {

    if (this.previousPressed !== pressedSide) {

      this.inputFrame = 0

    }
    if (this.inputFrame < movementConfig.steeringSpeed) {

      this.inputFrame += 1

    }
    this.previousPressed = pressedSide

  }

  /**
   * Krok
   * @returns number
   */
  private getStep(inTurn: boolean): number {

    return inTurn ?
      this.inputFrame / movementConfig.steeringSpeed * movementConfig.maxSteeringCurve :
      this.inputFrame / movementConfig.steeringSpeed * movementConfig.maxSteeringStraight

  }

  /**
   * Skontrolovanie limitov vlavo
   */
  private checkLimitsLeft(): void {

    if (this.actualPercent < this.limitLeft) {

      this.actualPercent = this.limitLeft
      this.reboundForce = movementConfig.rebound.stepCoef * speedManager.getActualSpeed()
      this.reboundForceFrame = movementConfig.rebound.frames
      speedManager.slowDownAfterImpact()

      this.showImpactEffect(Sides.LEFT)

    }

  }

  /**
   * Skontrolovanie limitov vpravo
   */
  private checkLimitsRight(): void {

    if (this.actualPercent > this.limitRight) {

      this.actualPercent = this.limitRight
      this.reboundForce = -movementConfig.rebound.stepCoef * speedManager.getActualSpeed()
      this.reboundForceFrame = movementConfig.rebound.frames
      speedManager.slowDownAfterImpact()

      this.showImpactEffect(Sides.RIGHT)

    }

  }

  /**
   * Zobrazenie impact grafiky
   * @param side - strana narazu
   */
  private showImpactEffect(side: Sides) {

    store.commit('ImpactState/SET_STATE', { side,
      isVisible: true })
    this.impactEffectTween?.kill()
    this.impactEffectTween = gsap.to({}, {
      duration: 0.5,
      onComplete: this.hideImpactEffect
    })

  }

  /**
   * Schovanie impact grafiky
   */
  private hideImpactEffect(): void {

    store.commit('ImpactState/SET_STATE', { isVisible: false })

  }

  /**
   * Nastavenie penalizacie za vyjazd zo zakruty
   * @param offsetFromIdeal - Hodnota offsetu od idealnej linie
   */
  public setExitTurnPenalty(offsetFromIdeal: number): void {

    const sectorData = player.hillLinesManager.getHillLineNormalizer().getActualSectorData()
    const leftToRight = sectorData.type === TriggersTypes.sectorLeftToRightEnd
    const rightToLeft = sectorData.type === TriggersTypes.sectorRightToLeftEnd

    let sign = 0
    if (leftToRight && offsetFromIdeal < 0) sign = 1
    if (rightToLeft && offsetFromIdeal > 0) sign = -1

    const { coef, frames } = movementConfig.exitTurnPenalty
    const exitTurnPenalty = coef * Math.abs(offsetFromIdeal) * sign

    if (exitTurnPenalty !== 0) {

      this.exitTurnPenalty = exitTurnPenalty
      this.exitTurnPenaltyFrame = frames
      console.log(`Penalizacia za idealny offset ${exitTurnPenalty}, offset ${offsetFromIdeal}`)

    }

  }

  /**
   * Penalizacia za vyjazd zo zakruty
   */
  private updateExitTurnPenalty(): void {

    if (this.exitTurnPenaltyFrame > 0) {

      this.exitTurnPenaltyFrame--
      this.actualPercent += this.exitTurnPenalty

      if (this.exitTurnPenaltyFrame === 0) console.log('skoncila penalizacia za vyjazd')

    }

  }

  /**
   * Sila odrazu od mantinela
   */
  private updateReboundForce(): void {

    if (this.reboundForceFrame > 0) {

      this.reboundForceFrame--
      this.actualPercent += this.reboundForce

      if (this.reboundForceFrame === 0) console.log('skoncila rebound sila')

    }

  }

  /**
   * Aktualizovanie sil v zakrute
   */
  private updateTurnForces(sectorType: TriggersTypes): void {

    // penalizacia za vyjazd zo zakruty
    this.updateExitTurnPenalty()

    if (!this.inTurn || !this.inFullTurn) {

      store.commit('MainState/SET_STATE', {
        centrifugalForce: 0,
        centripetalForce: 0
      })
      return

    }

    const speed = speedManager.getActualSpeed()
    if (speed < movementConfig.minCentrifugalSpeed) {

      this.updateCentripetalForce(speed, sectorType)

    } else {

      this.updateCentrifugalForce(speed, sectorType)

    }

  }

  /**
   * Poriesenie dostredivej sily
   * @param speed - aktualna rychlost
   * @param inTurn - True, ak sme v zakrute
   * @param sectorType - Typ sektoru
   */
  private updateCentripetalForce(
    speed: number,
    sectorType: TriggersTypes
  ): void {

    // znamienko, ci davame dostredivu silu vlavo alebo vpravo (je to opacne ako odstrediva)
    const sign = sectorType === TriggersTypes.sectorLeftToRightEnd ? 1 : -1

    const { centripetalForceMax, minCentrifugalSpeed } = movementConfig
    const centripetalForce = (centripetalForceMax - (centripetalForceMax *
            (speed / minCentrifugalSpeed))) * sign

    store.commit('MainState/SET_STATE', {
      centripetalForce,
      centrifugalForce: 0
    })

    this.actualPercent += centripetalForce

  }

  /**
   * Poriesenie odstredivej sily
   * @param speed - aktualna rychlost
   * @param inTurn - True, ak sme v zakrute
   * @param sectorType - Typ sektoru
   */
  private updateCentrifugalForce(
    speed: number,
    sectorType: TriggersTypes
  ): void {

    // znamienko, ci davame odstredivu silu vlavo alebo vpravo
    const sign = sectorType === TriggersTypes.sectorLeftToRightEnd ? -1 : 1

    const {
      centrifugalForceMax, maxSpeedCoef, minCentrifugalSpeed
    } = movementConfig
    const speedValue = speed - minCentrifugalSpeed
    const centrifugalForce = centrifugalForceMax * (speedValue / maxSpeedCoef) * sign

    store.commit('MainState/SET_STATE', {
      centrifugalForce,
      centripetalForce: 0
    })

    this.actualPercent += centrifugalForce

  }

  /**
   * Vratenie offsetu percent kvoli zakrutam
   * @param actualPercent - Hodnota aktualnych percent na vypocet
   * @param inTurn - Ci je v zakrute
   * @param fullTurnType - typ zakruty (plna, start, end)
   * @returns Offset %
   */
  private getOffsetPercent(
    actualPercent: number,
    inTurn: boolean,
    fullTurnType: SectorFullTurnTypes
  ): number {

    if (!inTurn) return 0

    const hillLineNormalizer = player.hillLinesManager.getHillLineNormalizer()
    const sectorFullTurnConfig = hillLineNormalizer.getSectorFullTurnConfig()
    const sectorConfig = hillLineNormalizer.getSectorConfig()
    const actualIndex = hillLineNormalizer.getActualSectorIndex()

    let offsetPercent = movementConfig.offsetPercent

    // na zaciatku zakruty davame offsetPercent v rozmedzi 0 do default, podla pozicie
    if (fullTurnType === SectorFullTurnTypes.minimumOnStart) {

      const startPercent = sectorConfig[actualIndex - 1].points[CurveTypes.left]
      const endPercent = sectorFullTurnConfig[actualIndex - 1].points[CurveTypes.left]
      const percent = (actualPercent - startPercent) / (endPercent - startPercent)

      offsetPercent *= percent

    }

    // na konci zakruty davame offsetPercent v rozmedzi default do 0, podla pozicie
    if (fullTurnType === SectorFullTurnTypes.minimumOnEnd) {

      const startPercent = sectorFullTurnConfig[actualIndex].points[CurveTypes.left]
      const endPercent = sectorConfig[actualIndex].points[CurveTypes.left]
      const percent = (actualPercent - startPercent) / (endPercent - startPercent)

      offsetPercent *= (1 - percent)

    }

    return offsetPercent

  }

  /**
   * Vypocitanie limitov, kvoli zakrutam
   * @param offsetPercent - Offset % kvoli zakrutam
   */
  private calculateLimits(offsetPercent: number): void {

    if (!this.inTurn) {

      this.limitLeft = movementConfig.limitLeft
      this.limitRight = movementConfig.limitRight
      return

    }

    const percentInTurn = player.hillLinesManager.getPercentInTurn()

    // v pravotocivej zakrute akoby predlzujeme min + menime limity
    if (this.inTurnLeftToRight) {

      const minRight = movementConfig.limitRight
      const maxRight = movementConfig.limitRightInner

      this.limitLeft = movementConfig.limitLeft - offsetPercent
      this.limitRight = minRight + ((maxRight - minRight) * percentInTurn)

    }

    // v lavotocivej zakrute akoby predlzujeme max + menime limity
    if (this.inTurnRightToLeft) {

      const minLeft = movementConfig.limitLeft
      const maxLeft = movementConfig.limitLeftInner

      this.limitLeft = minLeft + ((maxLeft - minLeft) * percentInTurn)
      this.limitRight = movementConfig.limitRight + offsetPercent

    }

  }

  /**
   * Vypocitanie virtualnych percent, kvoli zakrutam
   * @param actualPercent - Aktualne %
   * @param offsetPercent - Offset % kvoli zakrutam
   * @returns Virtualne %
   */
  private calculateVirtualPercent(
    actualPercent: number,
    offsetPercent: number,
    inTurn: boolean,
    inTurnLeftToRight: boolean,
    inTurnRightToLeft: boolean
  ): number {

    if (!inTurn) return actualPercent

    let min = 0
    let max = 1

    // v pravotocivej zakrute akoby predlzujeme min + menime limity
    if (inTurnLeftToRight) {

      min = 0 - offsetPercent
      max = 1

    }

    // v lavotocivej zakrute akoby predlzujeme max + menime limity
    if (inTurnRightToLeft) {

      min = 0
      max = 1 + offsetPercent

    }

    // ked sme v zakrute, tak musime spravit prepocitanie percent, podla typu zakruty
    return (actualPercent - min) / (max - min)

  }

  /**
   * Sprava auto movementu
   */
  public manageAutoMove(): void {

    if (!gameConfig.idealAutoMove.drive) return

    const hillLineNormalizer = player.hillLinesManager.getHillLineNormalizer()
    const sectorData = hillLineNormalizer.getActualSectorData()
    if (this.inTurn && this.inFullTurn) {

      const actualSectorIndex = hillLineNormalizer.getActualSectorIndex()
      const percentWide = player.hillLinesManager.getOffsetForIdealLineInTurn(
        actualSectorIndex,
        sectorData.type
      )
      playerMovementManager.setActualPercent(percentWide)

    } else {

      this.setActualPercent(0.5)

    }

  }

  /**
   * Prepocitanie aktualnych % do virtualnych
   * @param actualPercent - Aktualne %
   * @param linePercent - % na krivke podla daneho sektora
   * @param sectorType - Typ sektoru
   * @param sectorIndex - Index sektoru
   * @returns Virtualne %
   */
  public convertActualToVirtual(
    actualPercent: number,
    linePercent: number,
    sectorType: TriggersTypes,
    sectorIndex: number
  ): number {

    const inTurnLeftToRight = sectorType === TriggersTypes.sectorLeftToRightEnd
    const inTurnRightToLeft = sectorType === TriggersTypes.sectorRightToLeftEnd
    const inTurn = inTurnLeftToRight || inTurnRightToLeft
    const fullTurnType = triggersManager.getFullTurnTypeByPercent(linePercent, sectorIndex)

    return this.calculateVirtualPercent(
      actualPercent,
      this.getOffsetPercent(linePercent, inTurn, fullTurnType),
      inTurn,
      inTurnLeftToRight,
      inTurnRightToLeft
    )

  }

  /**
   * Aktualizovanie veci
   */
  public update(): void {

    if (!this.moveLeftRight) return

    // zistime, ci je v zakrute
    const hillLineNormalizer = player.hillLinesManager.getHillLineNormalizer()
    const sectorData = hillLineNormalizer.getActualSectorData()
    this.inTurnLeftToRight = sectorData.type === TriggersTypes.sectorLeftToRightEnd
    this.inTurnRightToLeft = sectorData.type === TriggersTypes.sectorRightToLeftEnd
    this.inTurn = this.inTurnLeftToRight || this.inTurnRightToLeft
    this.inFullTurn = hillLineNormalizer.fullTurnType === SectorFullTurnTypes.fullOnMiddle

    // ak nie je automove pre idealny prechod vsetkym
    if (!gameConfig.idealAutoMove.drive) {

      const mobileInputsX = store.getters['MovementState/getPositionX']

      if (inputsManager.moveDirectionLeft && inputsManager.moveDirectionRight) {

        this.previousPressed = undefined

      } else if (inputsManager.moveDirectionLeft || mobileInputsX < 0) {

        this.toLeftSide(this.inTurn)

      } else if (inputsManager.moveDirectionRight || mobileInputsX > 0) {

        this.toRightSide(this.inTurn)

      }

      this.updateTurnForces(sectorData.type)
      this.updateReboundForce()

    }

    const offsetPercent = this.getOffsetPercent(
      player.hillLinesManager.getActualPercent(),
      this.inTurn,
      hillLineNormalizer.fullTurnType
    )

    this.calculateLimits(offsetPercent)

    this.checkLimitsLeft()
    this.checkLimitsRight()

    this.virtualPercent = this.calculateVirtualPercent(
      this.actualPercent,
      offsetPercent,
      this.inTurn,
      this.inTurnLeftToRight,
      this.inTurnRightToLeft
    )

  }

  /**
   * reset
   */
  public reset(): void {

    this.actualPercent = movementConfig.center
    this.virtualPercent = movementConfig.center

  }

}

export const playerMovementManager = new PlayerMovementManager()
