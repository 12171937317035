<template>
  <div
    id="app"
    class="app"
  >
    <div
      style="z-index: 1000000;"
    >
      <div v-show="!modalActive">
        <impact-effect
          :is-visible="impactState.isVisible"
          :side-x="impactState.side.toLowerCase()"
        />
        <hud-component v-show="debugState.isHudActive" />
        <error-modal
          class="pointer-events-auto"
          @close="closeApp"
        />
        <finish-top-box
          v-if="uiState.showFinishTopBox"
          :show-first-box="finishTopBox.showFirstBox"
          :personal-best="finishTopBox.personalBest"
          :new-personal-best="finishTopBox.newPersonalBest"
          :first-place="finishTopBox.firstPlace"
          :show-second-box="finishTopBox.showSecondBox"
          :time="finishTopBox.time"
          :position="finishTopBox.position"
        />
        <push-bar-component />
        <push-success-component />
        <phase-start />
        <time-keeper-component />
        <traffic-component />
        <data-table />
        <info-component />
        <mobile-inputs
          v-if="isMobile"
          class="pointer-events-auto"
        />
        <split-times-downhill v-if="uiState.showSplitTimes" />
        <split-position-text />
        <downhill-times-component v-if="!uiState.isTraining" />
        <speed-meter-component />
        <training-layout v-if="uiState.showTrainingLayout" />
        <training-results v-if="trainingResultsState.showResults" />
        <info-keys v-if="!isMobile" />
        <black-overlay />
      </div>
      <fps-component />
      <tutorial-logic-component
        v-show="!modalActive"
      />
      <tutorial-info-keys v-show="!modalActive && !isMobile && isTutorial" />
      <menu-section
        v-if="showMenu"
        :is-mobile="isMobile"
        style="pointer-events: auto"
        :is-app-wsm="isAppWSM2021"
        :show-overlay="modalActive"
        @toggle-ui="toggleUi"
      />
      <color-overlay-component
        v-if="isBlurActive && !blurState.isToggle"
        :opacity="0.6"
      />
      <loading-circle-overlay />
    </div>
    <loading />
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'

// Internal Package codes
import {
  MobileDetector,
  requestManager,
  modes,
  game,
  inputsManager
} from '@powerplay/core-minigames'
import {
  ErrorModal,
  LoadingCircleOverlay,
  ImpactEffect,
  ColorOverlayComponent,
  BlackOverlay
} from '@powerplay/core-minigames-ui'

// Component codes

// Debug Components
import HudComponent from '@/components/DebugComponents/HUD.vue'
// import DirectionsInfo from '@/components/DebugComponents/DirectionsInfo.vue'

// Game Components
import MobileInputs from '@/components/Inputs/MobileInputs.vue'
import PhaseStart from '@/components/PhaseStart.vue'
import SplitTimesDownhill from '@/components/SplitTimesDownhill.vue'
import FinishTopBox from '@/components/FinishTopBox.vue'
import DataTable from '@/components/Table/DataTable.vue'
import Loading from '@/components/Loading.vue'
import InfoComponent from '@/components/InfoComponent.vue'
import TimeKeeperComponent from '@/components/TimeKeeperComponent/index.vue'
import MenuSection from '@/components/MenuSection/index.vue'
import FpsComponent from '@/components/FpsComponent.vue'
import DownhillTimesComponent from '@/components/DownhillTimesComponent.vue'
import TrafficComponent from '@/components/TrafficComponent.vue'
import TrainingLayout from '@/components/Training/TrainingLayout.vue'
import TrainingResults from '@/components/Training/TrainingResults.vue'
import PushBarComponent from '@/components/PushBarComponent/index.vue'
import InfoKeys from '@/components/InfoKeys.vue'
import TutorialLogicComponent from '@/components/Tutorial/TutorialLogicComponent.vue'
import SpeedMeterComponent from '@/components/SpeedMeterComponent.vue'
import TutorialInfoKeys from '@/components/Tutorial/TutorialInfoKeys.vue'
import SplitPositionText from '@/components/SplitPositionText/index.vue'
import PushSuccessComponent from '../PushSuccessComponent/index.vue'

// Internal Codes
import { initiateListeners } from '@/helpers/initiateListeners'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

export default defineComponent({
  name: 'StructureComponent',
  components: {
    HudComponent,
    // DirectionsInfo,
    Loading,
    MobileInputs,
    BlackOverlay,
    PhaseStart,
    MenuSection,
    SplitTimesDownhill,
    TimeKeeperComponent,
    FinishTopBox,
    ErrorModal,
    DataTable,
    InfoComponent,
    FpsComponent,
    TrafficComponent,
    DownhillTimesComponent,
    TrainingLayout,
    TrainingResults,
    PushBarComponent,
    InfoKeys,
    LoadingCircleOverlay,
    TutorialLogicComponent,
    SpeedMeterComponent,
    TutorialInfoKeys,
    ImpactEffect,
    SplitPositionText,
    ColorOverlayComponent,
    PushSuccessComponent
  },
  data() {

    return {
      modalActive: false,
      isAppWSM2021: false
    }

  },
  computed: {
    ...mapGetters({
      uiState: 'UiState/getUiState',
      impactState: 'ImpactState/getImpactState',
      debugState: 'DebugState/getDebugState',
      finishTopBox: 'FinishTopBoxState/getfinishTopBoxState',
      isBlurActive: 'BlurState/getIsActive',
      blurState: 'BlurState/getBlurState',
      trainingResultsState: 'TrainingResultsState/getTrainingResultsState'
    }),
    isMobile(): boolean {

      return MobileDetector.isMobile()

    },
    isTutorial(): boolean {

      return modes.isTutorial()

    },
    showMenu(): boolean {

      return !this.showLoading() && !this.trainingResultsState.showResults

    }
  },
  watch: {
    isBlurActive(newState) {

      if (newState) {

        document.getElementsByTagName('canvas')[0].classList.add('blur-class')

      } else {

        document.getElementsByTagName('canvas')[0].classList.remove('blur-class')

      }

      // osetrenie vypnutia menu pocas zobrazenej tabulky
      if (!newState && this.blurState.isTable) {

        this.$store.commit('BlurState/SET_IS_ACTIVE', true)

      }

    }
  },
  created(): void {

    this.$store.commit('LoadingState/SET_STATE', {
      showLoading: true,
      loadingProgress: 0
    })

    this.isAppWSM2021 = modes.isAppWSM2021()
    initiateListeners(this)
    tutorialUIChange.registerVM(this)

  },
  methods: {
    showLoading(): void {

      return this.$store.getters['LoadingState/getLoadingState'].showLoading

    },
    closeApp(): void {

      disciplinePhasesManager.prematureEnded = true
      this.$store.commit('LoadingCircleState/SET_STATE', {
        isActive: true
      })
      requestManager.redirect(true)

    },
    toggleUi(toggle = true) {

      if (toggle) {

        this.$store.commit('BlurState/SET_IS_ACTIVE', true)
        this.$store.commit('BlurState/SET_IS_TOGGLE', true)
        inputsManager.actionInputsBlocked = true
        this.modalActive = true
        game.pauseGame()

      } else {

        inputsManager.actionInputsBlocked = false
        this.modalActive = false
        this.$store.commit('BlurState/SET_IS_ACTIVE', false)
        this.$store.commit('BlurState/SET_IS_TOGGLE', false)
        game.resumeGame()

      }

    }

  }
})
</script>
<style>
.blur-class {
    filter: blur(4px);
}
</style>

<style lang="less" scoped>
.app {
    min-height: 100%;
    min-width: 100%;
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.absolute {
    position: absolute;
}

.midder {
    top: 50%;
    left: 50%
}

</style>
