<template>
  <div class="push-bar-positioner">
    <push-bar
      v-if="pushBarState.visible"
      :number-location="pushBarState.numberLocation"
      :show-opacity-point="pushBarState.showOpacityPoint"
      :number-location-with-opacity="pushBarState.numberLocationWithOpacity"
      :text="pushBarState.text"
      :color-point="pushBarState.colorPoint"
      :color-opacity-point="pushBarState.colorOpacityPoint"
      :glow="pushBarState.glow"
      :show-main-point="pushBarState.showMainPoint"
      :show-left-arrow="arrowsVisibility.showLeftArrow"
      :show-right-arrow="arrowsVisibility.showRightArrow"
      :show-right-miss="missedState.rightMissed"
      :show-left-miss="missedState.leftMissed"
    />
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'

// Internal Package codes
import { PushBar } from '@powerplay/core-minigames-ui'

export default defineComponent({
  name: 'PushBarComponent',
  components: {
    PushBar
  },
  computed: {
    ...mapGetters({
      pushBarState: 'PushBarState/getPushBarState',
      arrowsVisibility: 'PushBarState/getArrowsVisibility',
      missedState: 'PushBarState/getMissedState'
    })
  }
})
</script>

<style lang="less" scoped>

  .push-bar-positioner {
        width: 100%;
        height: 50%;
        left: 0;
        bottom: 0;
        position: absolute;
        justify-content: center;
        display: flex;

        .push-bar {
            position: absolute;
            width: fit-content;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            bottom: 5%;
            transform-origin: bottom;
        }
  }

</style>
