import { player } from '../../entities/player'
import {
  AudioGroups,
  AudioNames,
  type DisciplinePhaseManager,
  PlayerAnimationsNames,
  TutorialObjectiveIds
} from '../../types'
import store from '@/store'
import {
  minigameConfig,
  timeManager,
  playersManager,
  PlayersSortTypes,
  fpsManager,
  cameraManager,
  modes,
  corePhasesManager,
  gsap,
  audioManager,
  CameraStates,
  TimesTypes,
  trainingManager,
  game
} from '@powerplay/core-minigames'
import { endManager } from '@/app/EndManager'
import { playerAnimationManager } from '@/app/entities/player/PlayerAnimationManager'
import { finishPhaseConfig } from '@/app/config'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import { SplitTimeManager } from '@/app/entities/player/SplitTimeManager'
import { triggersManager } from '@/app/entities/trigger/TriggersManager'
import { playerMovementManager } from '@/app/entities/player/PlayerMovementManager'
import { trainingTasks } from '@/app/modes/training/TrainingTasks'
import { inputsManager } from '@/app/InputsManager'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'

/**
 * Trieda fazy pre dojazd v cieli (resp naburanie)
 */
export class FinishPhaseManager implements DisciplinePhaseManager {

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /** tween na ukoncenie fazy po animacii */
  private finishPhaseTween !: gsap.core.Tween

  /** ci faza skoncila */
  private ended = false

  /** pocitadlo framov */
  private frameCount = 0

  /** ci sme prepli na camera outro */
  private isCameraOutro = false

  /** ci sme nastavili finish phase tween */
  private isFinishPhaseTweenSet = false

  /** tween na zmenu UI stavu */
  private changeUiStateTween!: gsap.core.Tween

  /** kolko po starte mame zobrazit finish top box */
  private SHOW_FINISH_TOP_BOX_SECONDS = 2

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    fpsManager.pauseCounting()

    game.shadowsManager.adjustPositionY(0.2)

    this.reset()
    timeManager.setActive(TimesTypes.game, false)

    if (modes.isTutorial()) {

      const wasFast = tutorialObjectives.checkIfObjectivePassedById(TutorialObjectiveIds.speed)
      if (!wasFast) tutorialObjectives.failObjective(TutorialObjectiveIds.speed)

    }

    playerMovementManager.changeMoveLeftRightActive(false)

    playersManager.setPlayerResults(timeManager.getGameTimeWithPenaltyInSeconds(true, 3))

    playersManager.setStandings()
    console.log('STANDINGS', playersManager.getStandings())
    store.commit('TableState/SET_DATA', playersManager.getStandings())

    console.warn('finish phase started')
    store.commit('InputsState/SET_VISIBLE', false)

    player.finishAction()

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: !(modes.isTutorial() || modes.isTrainingMode()),
      showSplitTimes: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

    this.changeUiStateTween = gsap.to({}, {
      duration: this.SHOW_FINISH_TOP_BOX_SECONDS,
      onComplete: () => {

        store.commit('UiState/SET_STATE', {
          showTimeKeeper: false,
          showSplitTimes: false,
          showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
          showTrainingLayout: modes.isTrainingMode(),
          isTraining: modes.isTrainingMode()
        })
        this.setFinishTopBoxData()

      }
    })

    // ulozime si poslednu ulohu v treningu
    trainingTasks.saveLastTasksValues()
    store.commit('TrainingState/SET_HIGH_SCORE', {
      newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
      showNewHighScore: trainingManager.isNewHighScore()
    })

    // data pre tabulku
    this.setDataForPositionsTable()
    store.commit(
      'GameplayTableState/SET_TABLES_VISIBILITY',
      {
        showTables: !modes.isTutorial(),
        showLeftTable: !modes.isTutorial(),
        showRightTable: !modes.isTutorial()
      }
    )

    this.setAnimations()
    this.playCommentatorAudio()
    this.playFinishAudio()
    this.setMovementStuff()

    player.setEnabledOptimalizationForDrive(false)
    this.changeCameraSettings()

    if (modes.isTutorial() || modes.isTrainingMode()) return

    const bestTime = playersManager
      .getBestResultPlayerInfo(PlayersSortTypes.ascending, true).finalResult ?? minigameConfig.dnfValue
    const dnfException = bestTime === minigameConfig.dnfValue

    // if (bestTime === minigameConfig.dnfValue) {

    //     bestTime = playersManager.getPlayerMainResult()

    // }

    const actualTime = timeManager.getGameTimeWithPenaltyInSeconds(true, 3)
    const difference = actualTime - (bestTime ?? actualTime)
    const differencePrefix = SplitTimeManager.getDifferencePrefix(difference)
    const differenceText = dnfException ?
      '' :
      SplitTimeManager.formatDifferenceTime(difference, differencePrefix)

    store.commit(
      'SplitTimeState/ADD_SPLIT_TIME',
      { text: differenceText,
        color: SplitTimeManager.getColor(difference) }
    )
    const timeSeconds = playersManager.getPlayer().resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main || 0
    store.commit('TimeState/SET_SHOW_BOOLEANS', {
      v2Expanded: false,
      showDiff: !dnfException,
      diffIndex: triggersManager.splitTimeManager.getSplitCount(),
      isV1: false,
      doTimeUpdate: false,
      bestTime: timeSeconds,
      isFinish: true
    })

  }

  /**
   * nastavime data pre top box
   */
  private setFinishTopBoxData(): void {

    if (modes.isDailyLeague() && !playersManager.isPlayerImproved()) return

    const timeSeconds = timeManager.getGameTimeWithPenaltyInSeconds()
    const personalBest = playersManager.getPlayer().personalBest
    const timeFormat = timeManager.getTimeInFormatFromSeconds(timeSeconds)
    const position = playersManager.getPlayerActualPosition()

    const showFirstBox = position < 4
    const showSecondBox = timeSeconds <= personalBest

    store.commit('FinishTopBoxState/SET_STATE', {
      showFirstBox: showFirstBox,
      showSecondBox: showSecondBox,
      firstPlace: position === 1,
      personalBest: timeSeconds === personalBest,
      newPersonalBest: timeSeconds < personalBest,
      time: timeFormat,
      position: position
    })

  }

  /**
   * zmenime camera settings
   */
  private changeCameraSettings(): void {

    if (!finishPhaseConfig.cameraSettings.enabled) return

    player.changeCameraSettings(
      finishPhaseConfig.cameraSettings.idealOffset,
      finishPhaseConfig.cameraSettings.idealLookAt,
      finishPhaseConfig.cameraSettings.coefSize,
      finishPhaseConfig.cameraSettings.changeLerp,
      finishPhaseConfig.cameraSettings.isStaticMovement
    )

  }

  /**
   * nastavime animacie na zaciatku fazy
   */
  private setAnimations(): void {

    playerAnimationManager.brakeAnimation()

  }

  /**
   * nastavime pohybove veci
   */
  private setMovementStuff(): void {

    speedManager.setEnabledForceForward(false)
    speedManager.setEnabledForceBackward(false)
    speedManager.setAccelerationEnabled(false)
    if (finishPhaseConfig.slowdown.startSpeed) {

      speedManager.setActualSpeed(finishPhaseConfig.slowdown.startSpeed ?? 1)

    }
    speedManager.setSpeedDecrease(
      true,
      finishPhaseConfig.slowdown.coef,
      finishPhaseConfig.slowdown.minSpeed,
      finishPhaseConfig.slowdown.frameFreq
    )

  }

  /**
   * spustime konecne audio pohybu
   */
  private playFinishAudio(): void {

    audioManager.stopAudioByName(AudioNames.ride)
    audioManager.stopAudioByName(AudioNames.corner)
    audioManager.play(AudioNames.break)

  }

  /**
   * zapneme komentatora pri finishi
   */
  private playCommentatorAudio(): void {

    // if (audioManager.isAudioGroupPlaying(AudioGroups.commentators)) return
    audioManager.stopAudioByGroup(AudioGroups.commentators)

    const rank = playersManager.getPlayerActualPosition()

    let audio = AudioNames.commentFinish4

    if (rank === 1) {

      audio = AudioNames.commentFinish1

    } else if (rank <= 3) {

      audio = AudioNames.commentFinish2

    } else if (rank <= (modes.isDailyLeague() || modes.isBossCompetition() ? 10 : 5)) {

      audio = AudioNames.commentFinish3

    }

    audioManager.play(audio)

  }

  /**
   * naplnime tabulku pozicii okolo hraca a cas hraca
   */
  public setDataForPositionsTable(): void {

    const data = playersManager.getSimilarPositionPlayersForTable()
    const tableData = []
    let playerResultString = ''

    for (let i = 0; i < data.length; i++) {

      if (data[i] === undefined) continue

      tableData.push({
        position: data[i].position,
        country: data[i].country,
        countryString: data[i].countryString,
        player: {
          name: data[i].name,
          isPlayer: data[i].playable
        },
        time: data[i].result,
        timeDiff: data[i].result,
        isBonus: false
      })

      if (data[i].playable) {

        playerResultString = data[i].result || ''

      }

    }
    store.commit(
      'GameplayTableState/SET_TABLE_DATA',
      tableData
    )

    const playerData = playersManager.getPlayer()

    console.log('Finalny cas', timeManager.getGameTimeWithPenaltyInSeconds())
    store.commit('GameplayTableState/SET_PLAYER_DATA', {
      position: playersManager.getPlayerActualPosition(),
      country: playerData.country,
      countryString: playerData.countryString,
      player: {
        name: playerData.name,
        isPlayer: true
      },
      time: timeManager
        .getTimeInFormatFromSeconds(playerData.resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main || 0),
      timeDiff: playerResultString,
      isBonus: !playerResultString.includes('+')
    })

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.frameCount++
    this.setCameraOutro()
    this.setFinishPhaseTween()

    if (!this.ended && inputsManager.actionPressed) this.skipPhase()

  }

  /**
   * cek ci nastavime outro kamery
   */
  private setCameraOutro(): void {

    if (this.isCameraOutro || this.frameCount % finishPhaseConfig.cameraOutroDelay) return

    this.isCameraOutro = true
    cameraManager.setState(CameraStates.disciplineOutro)
    cameraManager.playTween()

  }

  /**
   * preskocime fazu
   */
  private skipPhase(): void {

    if (this.ended) return
    player.hillLinesManager.setActualPercent(finishPhaseConfig.targetPosition - 0.1)
    speedManager.setSpeedDecrease(true, 0, 0, 1)
    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    this.finishPhase()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    if (this.ended) return
    this.ended = true

    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

    store.commit(
      'GameplayTableState/SET_TABLES_VISIBILITY',
      {
        showTables: false
      }
    )

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showSplitTimes: false,
      showFinishTopBox: false,
      showTrainingLayout: false,
      isTraining: modes.isTrainingMode()
    })

    fpsManager.pauseCounting()
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    console.warn('finish phase ended')
    this.callbackEnd()

  }

  /**
   * Vratenie konecnej emocie
   * @returns Emocia
   */
  public getEndEmotion = (): PlayerAnimationsNames => {

    if (modes.isTrainingMode()) return this.getTrainingEmotion()

    const pos = playersManager.getPlayerActualPosition()
    const time = timeManager.getGameTimeWithPenaltyInSeconds(true, 3)
    const personalBest = playersManager.getPlayer().personalBest
    const isPersonalBest = time < personalBest

    let animation = PlayerAnimationsNames.end

    if (pos <= 3 || isPersonalBest) {

      animation = PlayerAnimationsNames.happy

    }

    return animation

  }

  /**
   * emocia v treningu
   * @returns Emocia
   */
  private getTrainingEmotion(): PlayerAnimationsNames {

    const tasks = trainingManager.getTrainingTasks()
    const sum = tasks.reduce((prev, current) => prev + current.value, 0)
    const average = sum / tasks.length

    let animation = PlayerAnimationsNames.end
    // ak 3 hviezdy
    if (average > 0.9) animation = PlayerAnimationsNames.happy

    return animation

  }

  /**
   * sets tween to finish phase
   */
  public setFinishPhaseTween(): void {

    if (this.isFinishPhaseTweenSet ||
            finishPhaseConfig.targetPosition > player.hillLinesManager.getActualPercent()) return

    if (this.finishPhaseTween) this.finishPhaseTween.kill()

    this.isFinishPhaseTweenSet = true
    this.finishPhaseTween = gsap.to({}, {
      duration: finishPhaseConfig.finishPhaseTweenDuration,
      onComplete: () => {

        this.finishPhase()

      },
      callbackScope: this
    })

  }

  /**
   * reset fazy
   */
  public reset(): void {

    this.isCameraOutro = false
    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

  }

}
