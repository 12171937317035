import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface InputsState {
  disabled: boolean
  isVisible: boolean
  actionDisabled: boolean
  exitPressed: boolean
}

const initialState = () => ({
  disabled: true,
  isVisible: false,
  actionDisabled: false,
  exitPressed: false
})

const inputsState: Module<InputsState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getInputsState: (moduleState: InputsState) => moduleState,
    getDisabled: (moduleState: InputsState) => moduleState.disabled,
    getIsVisible: (moduleState: InputsState) => moduleState.isVisible,
    getIsActionDisabled: (moduleState: InputsState) => moduleState.actionDisabled,
    getExitPressed: (moduleState: InputsState) => moduleState.exitPressed
  },

  mutations: {
    RESET: (moduleState: InputsState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: InputsState, newState: InputsState) => {

      if (newState.disabled !== undefined) moduleState.disabled = newState.disabled
      if (newState.isVisible !== undefined) moduleState.isVisible = newState.isVisible

    },
    SET_DISABLED: (moduleState: InputsState, disabled: boolean) => {

      moduleState.disabled = disabled

    },
    SET_VISIBLE: (moduleState: InputsState, visible: boolean) => {

      moduleState.isVisible = visible

    },
    SET_IS_ACTION_DISABLED: (moduleState: InputsState, disabled: boolean) => {

      moduleState.disabled = disabled

    },
    SET_EXIT_PRESSED: (moduleState: InputsState, exitPressed: boolean) => {

      moduleState.exitPressed = exitPressed

    }
  }
}

export default inputsState
