<template>
  <section
    class="positioner"
  >
    <section
      v-if="tutorialButtons.showMovement || tutorialButtons.showAction"
    >
      <section
        v-if="!showJoystick && tutorialButtons.showMovement"
        class="tutorial-arrows-container"
      >
        <div
          class="arrow"
          style="position: absolute; left: 0; bottom: 0%;"
        >
          <arrow-animation
            style="position: absolute; width: 580px; height: 460px; bottom: 0; transform-origin: bottom left"
            position="bottom"
          />
        </div>
        <div
          class="arrow"
          style="position: absolute; right: 0; bottom: 0%;"
        >
          <arrow-animation
            style="position: absolute; width: 580px; height: 460px; bottom: 0; transform-origin: bottom right"
            position="bottom"
          />
        </div>
      </section>
      <movement-sides
        v-if="!showJoystick && tutorialButtons.showMovement"
        glow
        @position="changeMovement"
        @end-contact="endContact"
      />
      <section
        v-if="!gameState.isLeft"
        class="flex justify-between bottom-right"
        :style="{transform: `scale(${scaleCoef})`}"
      >
        <joy-stick
          v-if="showJoystick && tutorialButtons.showMovement"
          glow
        />
        <section
          v-else
          class="placeholder"
        />
        <section v-show="!tutorialButtons.showMovement" />
        <section
          class="relative"
        >
          <arrow-animation
            v-if="tutorialButtons.showAction"
            position="bottom"
            style="position: absolute; width: 580px; height: 460px; bottom: 0; pointer-events: none;"
            :is-scaled="false"
          />
          <mobile-button
            v-if="tutorialButtons.showAction"
            :type="type"
            :is-scaled="false"
            style="pointer-events: none"
            glow
            @mousedown.self="performAction"
            @mouseup.stop=""
            @touchstart.self="performAction"
            @touchend.stop=""
          />
        </section>
      </section>
      <section
        v-else
        class="flex justify-between bottom-left"
        :style="{transform: `scale(${scaleCoef})`}"
      >
        <section
          class="relative"
        >
          <arrow-animation
            v-if="tutorialButtons.showAction"
            position="bottom"
            style="position: absolute; width: 580px; height: 460px; bottom: 0; pointer-events: none;"
            :is-scaled="false"
          />
          <mobile-button
            v-if="tutorialButtons.showAction"
            :type="type"
            :is-scaled="false"
            style="pointer-events: none"
            glow
            @mousedown.self="performAction"
            @mouseup.stop=""
            @touchstart.self="performAction"
            @touchend.stop=""
          />
        </section>
        <joy-stick
          v-if="showJoystick && tutorialButtons.showMovement"
          glow
        />
        <section
          v-else
          class="placeholder"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import JoyStick from '../Inputs/JoyStick.vue'
import { mapGetters } from 'vuex'
import {
  ArrowAnimation,
  MovementSides,
  MobileButton,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  inputsManager,
  tutorialManager
} from '@powerplay/core-minigames'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

export default defineComponent({
  name: 'TutorialMobileInputs',
  components: {
    JoyStick,
    MovementSides,
    ArrowAnimation,
    MobileButton
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      gameState: 'GameSettingsState/getGameSettingsState',
      isDisabled: 'InputsState/getDisabled',
      buttonsVisible: 'InputsState/getIsVisible',
      showJoystick: 'ActionButtonState/getShowJoystick',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      movementButtonsActive: 'MovementButtonsState/getMovementButtonsActive',
      actionDisabled: 'InputsState/getIsActionDisabled',
      tutorialButtons: 'TutorialState/getTutorialButtons',
      isStart: 'ActionButtonState/isStart',
      isJumpIn: 'ActionButtonState/getIsJumpIn',

      typeWrite: 'TutorialCoreState/getTypeWriter'
    }),
    type() {

      if (this.isJumpIn) return 'jump-in'
      return 'bobsleigh-push'

    }
  },
  methods: {
    endContact() {

      this.$store.commit('MovementState/SET_POSITION_X', 0)

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovement(positionX: number) {

      this.$store.commit('MovementState/SET_POSITION_X', positionX)

    },
    performAction() {

      if (!this.isJumpIn) return // aby neskipovalo na starte pocast typewriteru
      if (tutorialManager.getTypeWriting()) {

        this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
        tutorialManager.setTypeWriting(false)

      } else if (!tutorialFlow.isEventActive) {

        tutorialFlow.eventActionPressed()

      }

    },
    endAction() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.placeholder {
  width: 300px;
}

.buttons-position {
  position: absolute;
  top: 0;
  left: 45%;
  transform: translate(-50%);
}
.tutorial-arrows-container {
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    .arrow {
        width: 580px;
        height: 100%;
        position: relative;
    }
}
.bottom-right {
  transform-origin: bottom right;
  display: flex;
  justify-content: flex-end;
}
.bottom-left {
  transform-origin: bottom left;
  display: flex;
  justify-content: flex-start;
}
</style>
