import store from '@/store'
import {
  type DisciplinePhaseManager,
  DisciplinePhases,
  Tasks,
  TutorialObjectiveIds
} from '../../types'
import {
  MobileDetector,
  THREE
} from '@powerplay/core-minigames'
import { playerAnimationManager } from '@/app/entities/player/PlayerAnimationManager'
import { pushbarManager } from '@/app/PushbarManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import type { StartPhaseManager } from '../StartPhase/StartPhase'
import { trainingTasks } from '@/app/modes/training/TrainingTasks'
import { endManager } from '@/app/EndManager'

/**
 * Trieda fazy pre rozbeh
 */
export class StartUpPhaseManager implements DisciplinePhaseManager {

  /** posledne miesto kde sme uspesne klikli */
  public startUpObject!: THREE.Mesh

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** pocet famov fazy */
  private frame = 0

  /** kolko framov na zaciatku sa ma ignorovat klik */
  private CLICK_DELAY = 5

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    this.createStartUpObject()
    speedManager.setActive(true)

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('StartUp phase started')
    this.preparePhase()

    playerAnimationManager.setRunAnimation()

  }

  /**
   * Vytvorenie objektu na idealne odstartovanie - pre debug ucely
   */
  public createStartUpObject(): void {

    /*
     * TODO: iba pre debug ucely
     * const geometry = new THREE.CircleGeometry(1)
     * const material = new THREE.MeshBasicMaterial({ color: 0xffff00 })
     * this.startUpObject = new THREE.Mesh(geometry, material)
     * game.scene.add(this.startUpObject)
     * this.startUpObject.rotation.set(Math.PI / 2 * 3, 0, 0)
     * this.startUpObject.position.set(0, 10, 0)
     */

  }

  /**
   * Zobrazenie mobilnych buttoniv
   */
  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    /*
     * store.commit('ActionButtonState/SET_SHOW_JOYSTICK', true)
     * store.commit('ActionButtonState/SET_START_BUTTON', false)
     */
    store.commit('InputsState/SET_DISABLED', false)

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.frame++
    pushbarManager.update(this.frame > this.CLICK_DELAY)

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    console.warn('StartUp phase ended')

    const startPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
    startPhase.disableSlowDownInTutorial()
    store.commit('PushBarState/SET_IS_VISIBLE', false)

    if (pushbarManager.getClickedCount() < 3) {

      tutorialObjectives.failObjective(TutorialObjectiveIds.startUp)

    } else {

      tutorialObjectives.passObjective(TutorialObjectiveIds.startUp)
      endManager.wasStartupSuccess = true

    }

    trainingTasks.countTaskValue(Tasks.startUp, pushbarManager.getSuccessCountQuality())

    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * reset
   */
  public reset(): void {

    // this.preparePhase()

  }

}
