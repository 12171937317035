import type { PushbarImageType } from '@/app/types'
import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface PushBarState {
  visible: boolean,
  numberLocation: number,
  showOpacityPoint: boolean,
  numberLocationWithOpacity: number,
  text: string,
  colorPoint: string,
  colorOpacityPoint: string,
  glow: boolean,
  showMainPoint: boolean,
  showLeftArrow: boolean,
  showRightArrow: boolean,
  rightMissed: boolean,
  leftMissed: boolean,
  successTracker: PushbarImageType[]
}

const initialState = () => ({
  visible: false,
  numberLocation: 0,
  showOpacityPoint: false,
  numberLocationWithOpacity: 0,
  text: '',
  colorPoint: 'blue',
  colorOpacityPoint: 'blue',
  glow: false,
  showMainPoint: true,
  showLeftArrow: false,
  showRightArrow: false,
  rightMissed: false,
  leftMissed: false,
  successTracker: []
})

const pushBarState: Module<PushBarState, RootState> = {
  state: initialState(),
  getters: {
    getPushBarState: (moduleState: PushBarState) => moduleState,
    isActive: (moduleState: PushBarState) => moduleState.visible,
    getArrowsVisibility: (moduleState: PushBarState) => (
      { showLeftArrow: moduleState.showLeftArrow,
        showRightArrow: moduleState.showRightArrow }
    ),
    getMissedState: (moduleState: PushBarState) => (
      {
        rightMissed: moduleState.rightMissed,
        leftMissed: moduleState.leftMissed
      }),
    getSuccessTracker: (moduleState: PushBarState) => moduleState.successTracker
  },
  mutations: {
    RESET: (moduleState: PushBarState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: PushBarState, newState: PushBarState) => {

      if (newState.visible !== undefined) moduleState.visible = newState.visible
      if (newState.numberLocation !== undefined) {

        moduleState.numberLocation = newState.numberLocation

      }
      if (newState.showOpacityPoint !== undefined) {

        moduleState.showOpacityPoint = newState.showOpacityPoint

      }
      if (newState.numberLocationWithOpacity !== undefined) {

        moduleState.numberLocationWithOpacity = newState.numberLocationWithOpacity

      }
      if (newState.text) moduleState.text = newState.text
      if (newState.glow) moduleState.glow = newState.glow
      if (newState.colorPoint) moduleState.colorPoint = newState.colorPoint
      if (newState.colorOpacityPoint) {

        moduleState.colorOpacityPoint = newState.colorOpacityPoint

      }
      if (newState.glow !== undefined) moduleState.glow = newState.glow

    },
    SET_IS_VISIBLE: (moduleState: PushBarState, visible: boolean) => {

      moduleState.visible = visible

    },
    SET_POSITION: (moduleState: PushBarState, position: number) => {

      moduleState.numberLocation = position

    },
    SET_OPACITY_POINT_POSITION: (moduleState: PushBarState, position: number) => {

      moduleState.numberLocationWithOpacity = position

    },
    SET_COLOR_POINT: (moduleState: PushBarState, color: string) => {

      moduleState.colorPoint = color

    },
    SET_COLOR_OPACITY_POINT: (moduleState: PushBarState, color: string) => {

      moduleState.colorOpacityPoint = color

    },
    SET_TEXT: (moduleState: PushBarState, text: string) => {

      moduleState.text = text

    },
    SHOW_OPACITY_POINT: (moduleState: PushBarState, show: boolean) => {

      moduleState.showOpacityPoint = show

    },
    SHOW_MAIN_POINT: (moduleState: PushBarState, show: boolean) => {

      moduleState.showMainPoint = show

    },
    SET_ARROWS_VISIBILITY: (
      moduleState: PushBarState,
      newState: { showLeftArrow: boolean, showRightArrow: boolean }
    ) => {

      moduleState.showLeftArrow = newState.showLeftArrow
      moduleState.showRightArrow = newState.showRightArrow

    },
    SET_MISSED_VISIBILITY: (moduleState: PushBarState, newState: { rightMissed: boolean, leftMissed: boolean }) => {

      if (newState.rightMissed !== undefined) moduleState.rightMissed = newState.rightMissed
      if (newState.leftMissed !== undefined) moduleState.leftMissed = newState.leftMissed

    },

    SET_SUCCESS_TRACKER: (moduleState: PushBarState, arr: PushbarImageType[]) => {

      moduleState.successTracker = [...arr]

    }
  },
  namespaced: true
}

export default pushBarState
